(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/bet-proposal/assets/javascripts/helpers/normalize-bet-proposal.js') >= 0) return;  svs.modules.push('/components/marketplace-data/bet-proposal/assets/javascripts/helpers/normalize-bet-proposal.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const _normalizeBetProposalBomben = betProposal => {
  const result = {};
  const EMPTY_ROW_OF_BOMBEN_CHOICES = [false, false, false, false, false, false, false, false, false, false, false];
  const isEmptyBetProposal = betProposal.every(rowItem => rowItem.choices.every(selection => !selection));
  if (isEmptyBetProposal) {
    return {};
  }
  const _addOneEventToResult = oneBasedEventNumber => {
    const rowNumberOfFirstParticipant = oneBasedEventNumber * 2 - 2;
    const dataOfFirstParticipant = betProposal.find(item => item.eventNumber === rowNumberOfFirstParticipant);
    const dataOfSecondParticipant = betProposal.find(item => item.eventNumber === rowNumberOfFirstParticipant + 1);
    result[oneBasedEventNumber] = [dataOfFirstParticipant ? dataOfFirstParticipant.choices : EMPTY_ROW_OF_BOMBEN_CHOICES, dataOfSecondParticipant ? dataOfSecondParticipant.choices : EMPTY_ROW_OF_BOMBEN_CHOICES];
  };
  const _isFirstItemInListWithGivenValue = (item, index, array) => array.indexOf(item) === index;
  const allZeroBasedRowNumbers = betProposal.map(rowItem => rowItem.eventNumber).sort();
  const allOneBasedEventNumbers = allZeroBasedRowNumbers.map(rowNumber => Math.floor(rowNumber / 2) + 1).filter(_isFirstItemInListWithGivenValue);
  allOneBasedEventNumbers.forEach(_addOneEventToResult);
  return result;
};
const _normalizeBetProposalFulltraff = betProposal => {
  const isEmptyBetProposal = betProposal.every(rowItem => rowItem.choices.every(selection => !selection));
  if (isEmptyBetProposal) {
    return {};
  }
  return betProposal.reduce((acc, _ref) => {
    let {
      eventNumber,
      choices
    } = _ref;
    return _objectSpread(_objectSpread({}, acc), {}, {
      [eventNumber]: choices.reduce((acc, val, key) => _objectSpread(_objectSpread({}, acc), {}, {
        [key]: val
      }), {})
    });
  }, {});
};
const normalizeBetProposal = function () {
  let betProposal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let productName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (productName === 'bomben') {
    return _normalizeBetProposalBomben(betProposal);
  }
  if (productName === 'fulltraff') {
    return _normalizeBetProposalFulltraff(betProposal);
  }
  const result = betProposal.reduce((acc, _ref2) => {
    let {
      eventNumber,
      choices
    } = _ref2;
    if (choices.length === 3) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        [eventNumber]: {
          1: choices[0],
          2: choices[1],
          4: choices[2]
        }
      });
    }
    return _objectSpread(_objectSpread({}, acc), {}, {
      [eventNumber]:
      choices.reduce((acc, val, key) => _objectSpread(_objectSpread({}, acc), {}, {
        [key + 1]: val
      }), {}) 
    });
  }, {});
  return result;
};
setGlobal('svs.components.marketplaceData.betProposal.helpers.normalizeBetProposal', normalizeBetProposal);

 })(window);