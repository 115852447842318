(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/bet-proposal/assets/javascripts/bet-proposal-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/bet-proposal/assets/javascripts/bet-proposal-actions.js');
"use strict";


const {
  services
} = svs.components.marketplaceData.betProposal;
const FETCH_BET_PROPOSAL_INIT = 'BET_PROPOSAL/FETCH_BET_PROPOSAL_INIT';
const FETCH_BET_PROPOSAL_SUCCESS = 'BET_PROPOSAL/FETCH_BET_PROPOSAL_SUCCESS';
const FETCH_BET_PROPOSAL_FAILURE = 'BET_PROPOSAL/FETCH_BET_PROPOSAL_FAILURE';
const fetchBetProposal = (activatedDrawId, groupId, productName) => dispatch => {
  dispatch({
    type: FETCH_BET_PROPOSAL_INIT,
    activatedDrawId
  });
  services.fetchBetProposal({
    activatedDrawId,
    groupId,
    productName
  }, (error, betProposal, isPrivate) => {
    if (error) {
      dispatch({
        type: FETCH_BET_PROPOSAL_FAILURE,
        activatedDrawId,
        error
      });
    } else {
      dispatch({
        type: FETCH_BET_PROPOSAL_SUCCESS,
        activatedDrawId,
        betProposal,
        isPrivate
      });
    }
  });
};
setGlobal('svs.components.marketplaceData.betProposal.actions', {
  FETCH_BET_PROPOSAL_INIT,
  FETCH_BET_PROPOSAL_SUCCESS,
  FETCH_BET_PROPOSAL_FAILURE,
  fetchBetProposal
});

 })(window);