(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/bet-proposal/assets/javascripts/services/fetch-bet-proposal.js') >= 0) return;  svs.modules.push('/components/marketplace-data/bet-proposal/assets/javascripts/services/fetch-bet-proposal.js');
"use strict";


const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const {
  helpers
} = svs.components.marketplaceData.betProposal;
const fetchBetProposal = (_ref, callback) => {
  let {
    activatedDrawId,
    groupId,
    productName
  } = _ref;
  const successCb = _ref2 => {
    let {
      preliminaryRow,
      isPrivate
    } = _ref2;
    let rows = [];
    if (preliminaryRow) {
      ({
        rows
      } = JSON.parse(preliminaryRow));
    }
    callback(null, helpers.normalizeBetProposal(rows, productName), isPrivate);
  };
  const errorCb = err => callback(err.responseJSON.error);
  fetchBetProposal.cacheRequest("/marketplace/1/activateddraws/".concat(activatedDrawId, "/preliminarywagers?groupId=").concat(groupId), successCb, errorCb);
};
fetchBetProposal.cacheRequest = cachedJupiterGet.createCache(1000);
setGlobal('svs.components.marketplaceData.betProposal.services.fetchBetProposal', fetchBetProposal);

 })(window);